import React, { useContext, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Box from '../../components/box'
import { AuthProvider, AuthContext } from '../../context/auth'
import config from '../../utils/config'
import checkUtmParams from '../../utils/checkUtmParams'
import { CopySubText, ErrorBanner } from '.'
import WorkspaceSetupSidebar from '../../components/workspaceSetupSidebar'
import axios from 'axios'
import useOnEnterEventListener from '../../utils/hooks/useOnEnterEventListener'
import handleAuthNetworkError from '../../utils/handleAuthNetworkError'
import { useCases } from '../../utils/constants/signup'
import useHotjarScript from '../../utils/hooks/useHotjarScript'

const SetGoalsContainer = () => (
  <AuthProvider>
    <SetGoals />
  </AuthProvider>
)

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  margin-top: 56px;
  /* margin-left: 80px; */
  margin-bottom: 93px;
`

export const Button = styled.button`
  width: 600px;
  height: 4rem;
  left: 0px;
  top: 0px;
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 1rem;
  color: #ffffff;
  text-transform: capitalize;
  border: none;
  background: black;
  cursor: pointer;
  margin-bottom: 0.75rem;
  font-family: ${props => props.theme.typography.fontFamilyGT};
  font-style: normal;
  ${props =>
    props.disabled
      ? css`
          background-color: #f6f6f6;
          color: #afafaf;
        `
      : ''};
`

const FormBox = styled.div`
  margin-top: 89px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const Header = styled.div`
  font-family: ${props => props.theme.typography.fontFamilyGT};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`

const GoalPill = styled.button`
  border: 2px solid #e2e2e2;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  height: 34px;
  display: flex;
  align-items: center;
  color: #545454;
  padding: 6px 10px;
  cursor: pointer;
  background-color: white;
  /* :focus {
    background-color: black;
    color: white;
  } */
  outline: 0;
  margin-bottom: 10px;
  margin-right: 8px;
  ${props =>
    props.selected
      ? css`
          background-color: black;
          color: white;
          border: 2px solid black;
        `
      : ''};
`

const GoalBox = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  overflow-y: hidden;
  flex-wrap: wrap;
  height: 420px;
  @media (min-width: 48.0625rem) {
    width: 700px;
    height: 240px;
  }
  transition: height 1s;
  ${props =>
    props.showAll
      ? css`
          height: 674px;
          @media (min-width: 48.0625rem) {
            width: 700px;
            height: 374px;
          }
        `
      : ''}
`

const GoalDivider = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`

const DividerLine = styled.div`
  height: 1px;
  flex: 1;
  background: rgba(0, 0, 0, 0.08);
  height: 1px;
  border: 1px;
  margin: auto;
`

const HiddenGoals = styled.div`
  width: 100%;
  height: 77px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.7) 0%,
    #ffffff 42.71%
  );
  position: absolute;
  bottom: 48px;
  @media (min-width: 48.0625rem) {
    width: 700px;
  }
  transition: height 1s;
  ${props =>
    props.showAll
      ? css`
          height: 0px;
        `
      : ''}
`

const SeeMoreButton = styled.div`
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: #757575;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 12px;
  cursor: pointer;
  height: 28px;
  width: 84px;
  display: grid;
  place-content: center;
`

const SetGoals = () => {
  const { signupStepsMap, token, setLoading } = useContext(AuthContext)
  const [selectedGoals, setSelectedGoals] = useState([])
  const [error, setError] = useState(null)
  const [showHiddenGoals, setShowHiddenGoals] = useState(false)
  // TODO: make dynamic if more options will be available in the future
  const [goalOptions, setGoalOptions] = useState(useCases)

  useEffect(() => {
    checkUtmParams()
  }, [])

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const result = await axios.post(
        `${config.backendUri}/auth/session`,
        {
          useCases: selectedGoals,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      navigate(signupStepsMap.get(result.data.signupStep))
    } catch (error) {
      setLoading(false)
      setError(handleAuthNetworkError(error))
    }
  }

  useOnEnterEventListener(selectedGoals.length, () => {
    handleSubmit()
  })

  useHotjarScript()

  return (
    <Layout>
      <SEO title="Set goals" />
      <WorkspaceSetupSidebar step={3} subStep={1} remainingTime="2 min" />
      <Container>
        <FormBox>
          <Box style={{ width: '600px' }}>
            <Header style={{ color: 'black', fontSize: '20px' }}>
              What would you like to set up Pulse for?
            </Header>
            <CopySubText
              style={{
                marginTop: '16px',
                fontSize: '14px',
                lineHeight: '22px',
              }}
            >
              Tell us about what you're trying to achieve and we'll tailor Pulse
              to your needs.
            </CopySubText>
            <GoalBox showAll={showHiddenGoals}>
              {goalOptions.slice(0, 8).map(goal => {
                const isSelected = selectedGoals.includes(goal.value)
                return (
                  <GoalPill
                    key={goal.value}
                    selected={isSelected}
                    onClick={() => {
                      if (isSelected) {
                        setSelectedGoals(
                          selectedGoals.filter(
                            selected => selected !== goal.value
                          )
                        )
                      } else {
                        setSelectedGoals([
                          ...new Set([...selectedGoals, goal.value]),
                        ])
                      }
                    }}
                  >
                    {goal.label}
                  </GoalPill>
                )
              })}
              <GoalDivider>
                <DividerLine />
                <SeeMoreButton
                  onClick={() => setShowHiddenGoals(!showHiddenGoals)}
                >
                  {showHiddenGoals ? 'See less' : '10+ more'}
                </SeeMoreButton>
                <DividerLine />
              </GoalDivider>
              {goalOptions.slice(9, goalOptions.length - 1).map(goal => {
                const isSelected = selectedGoals.includes(goal.value)
                return (
                  <GoalPill
                    key={goal.value}
                    selected={isSelected}
                    onClick={() => {
                      if (isSelected) {
                        setSelectedGoals(
                          selectedGoals.filter(
                            selected => selected !== goal.value
                          )
                        )
                      } else {
                        setSelectedGoals([
                          ...new Set([...selectedGoals, goal.value]),
                        ])
                      }
                    }}
                  >
                    {goal.label}
                  </GoalPill>
                )
              })}
            </GoalBox>
            <Box style={{ position: 'relative' }}>
              <HiddenGoals showAll={showHiddenGoals} />
              <Box
                style={{
                  background: '#FFFFFF',
                  marginTop: '16px',
                  height: '48px',
                }}
              >
                <Button
                  style={{ width: '100%', height: '48px' }}
                  disabled={!selectedGoals.length}
                  onClick={() => handleSubmit()}
                >
                  Continue
                </Button>
              </Box>
              {error && (
                <Box style={{ marginTop: '16px' }}>
                  <ErrorBanner>{error}</ErrorBanner>
                </Box>
              )}
            </Box>
          </Box>
        </FormBox>
      </Container>
    </Layout>
  )
}

export default SetGoalsContainer
